import React, { useEffect } from 'react'
import { Link, graphql, navigate } from 'gatsby'

import Img from 'gatsby-image/withIEPolyfill'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import { Container, PageWrapper } from '../../components/common/container'
import { Title } from '../../components/common/title'
import { HeroSection } from '../../components/common/hero-section'
import KnowledgeBaseSearchMini from '../../components/knowledge-base/knowledge-base-search-mini'

import queryString from 'query-string'

import colors from '../../data/color-pallette'
import breakpoints from '../../data/breakpoints'
import KnowledgeBaseSearch from '../../components/knowledge-base/knowledge-base-search'

import { debounce } from 'lodash-es'

const windowGlobal = typeof window !== 'undefined' && window

// https://thenounproject.com/search/?q=change&i=2277883

const CustomHeroSection = styled(HeroSection)`
  height: 300px;
`

const CustomContainer = styled(Container)`
  padding: 20px;

  /* This is done for the sake of specificity */
  @media (min-width: 0px) {
    max-width: 600px;
  }
`

const HeroTitle = styled(Title)`
  color: ${colors.white};
  margin-bottom: 12px;
`

const SectionTitle = styled(Title)`
  font-size: 32px;
  margin-bottom: 12px;
`

const KBPage = props => {
  const {
    data: { heroImage, knowledgeBasePosts },
  } = props

  let search = ''
  if (windowGlobal) {
    search = queryString.parse(windowGlobal.location.search).search
  }

  return (
    <Layout>
      <SEO title="StreamParrot - The best branding available for your chat overlay" />
      <PageWrapper>
        <div>
          <BackgroundImage
            fluid={{
              base64: heroImage.childImageSharp.lowRes.dataURI,
              src: heroImage.childImageSharp.hiRes.dataURI,
            }}
          >
            <CustomHeroSection>
              <HeroTitle>Knowledge Base</HeroTitle>
            </CustomHeroSection>
          </BackgroundImage>
        </div>
        <CustomContainer>
          <KnowledgeBaseSearch
            items={knowledgeBasePosts.edges}
            searchQuery={search}
            onSearchChange={debounce(event => {
              navigate(`/help/kb/?search=${event}`, {
                replace: true,
              })
            }, 300)}
          />
        </CustomContainer>
      </PageWrapper>
    </Layout>
  )
}

export default KBPage

export const pageQuery = graphql`
  query($SQIP_HERO_SHAPE_COUNT: Int, $SQIP_THUMB_SHAPE_COUNT: Int) {
    heroImage: file(relativePath: { regex: "/open-books.jpeg/" }) {
      ...HeroImage
    }
    knowledgeBasePosts: allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "help/kb" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          html
          frontmatter {
            postType
            path
            title
            date(formatString: "MMMM DD, YYYY")
            summary
            featuredImage {
              childImageSharp {
                sqip(
                  numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
                  blur: 0
                  width: 256
                  mode: 1
                ) {
                  dataURI
                }
              }
            }
          }
        }
      }
    }
  }
`
