import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import FontIcon from 'react-md/lib/FontIcons/FontIcon'
import TextField from 'react-md/lib/TextFields/TextField'

import colors from '../../data/color-pallette'
import { Container } from '../common/container'
import { Title } from '../common/title'
import breakpoints from '../../data/breakpoints'

import Fuse from 'fuse.js'

const SearchTextField = styled(TextField)`
  margin-bottom: 20px;
`

const NoResultsWrapper = styled.div`
  max-width: 400px;
  margin: 42px auto;
  text-align: center;

  p {
    text-align: left;
  }
`

const KnowledgeBaseLink = styled(Link)`
  display: block;
  padding: 0 30px;
  text-decoration: none;

  h3 {
    color: ${colors.secondary};
    margin-top: 20px;
  }

  hr {
    color: #ddd;
    width: 80%;
    margin: 20px auto 0;
    border-top: 0;
    border-left: 0;
  }

  &:last-child hr {
    display: none;
  }
`

const ShowingArticlesAmount = styled.div`
  text-align: center;
`

const KnowledgeBaseSearch = ({ items, searchQuery, onSearchChange }) => {
  let filteredItems = items

  const [searchValue, setSearchValue] = useState(searchQuery)
  const { current: fuseIndex } = useRef(
    new Fuse(items, {
      keys: ['node.frontmatter.title', 'node.frontmatter.summary'],
      threshold: 0.2,
    })
  )

  if (searchValue) {
    filteredItems = fuseIndex.search(searchValue)
  }

  const handleSearchChange = event => {
    setSearchValue(event)
    onSearchChange(event)
  }

  return (
    <>
      <div>
        <SearchTextField
          id="knowledge-base-search"
          type="text"
          placeholder="Search"
          leftIcon={<FontIcon>search</FontIcon>}
          value={searchValue}
          onChange={handleSearchChange}
        />
        {!!filteredItems.length && (
          <>
            <ShowingArticlesAmount>
              Showing <b>{filteredItems.length}</b> articles
            </ShowingArticlesAmount>
            <div>
              {filteredItems.map(
                ({
                  node: {
                    frontmatter: { path, title, summary },
                  },
                }) => (
                  <KnowledgeBaseLink to={path}>
                    <h3>{title}</h3>
                    <p>{summary}</p>
                    <hr />
                  </KnowledgeBaseLink>
                )
              )}
            </div>
          </>
        )}
        {!filteredItems.length && (
          <NoResultsWrapper>
            <h3>No Search Results Found</h3>
            <p>
              Sorry, we couldn't find what you were looking for. If you want to
              contact us directly you can do so in the Discord: <br />
              <a
                href="https://discord.gg/M2GJYRG"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://discord.gg/M2GJYRG
              </a>
            </p>
          </NoResultsWrapper>
        )}
      </div>
    </>
  )
}

KnowledgeBaseSearch.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  searchQuery: PropTypes.string,
  onSearchChange: PropTypes.func,
}

KnowledgeBaseSearch.defaultProps = {
  items: [],
  searchQuery: '',
  onSearchChange: () => {},
}

export default KnowledgeBaseSearch
